<template>
  <section>
    <h1 class="sr-only">Intro video</h1>
    <template v-if="videoCode">
      <YTPlayer
        v-if="!isOurVideo"
        :video-code="videoCode"
        autoplay
        class="fixed inset-0 w-full h-full"
        @end="navigateBack"
      />
      <VideoComponent
        v-else-if="videoCode"
        autoplay
        :src="videoCode"
        @ended="navigateBack"
        controls
        class="absolute top-0 left-0 w-full h-full object-contain --reset-flip"
      />
    </template>
    <v-btn
      color="red"
      dark
      class="fixed"
      style="right: 8px; bottom: 64px"
      @click="navigateBack"
      >Skip</v-btn
    >
  </section>
</template>

<script>
import { mapGetters } from "vuex"
import Vue from "vue"
import isVideo from "is-video"

import { PregameVideoStorage } from "@/helpers"
import VideoComponent from "@/components/ui/VideoComponent/VideoComponent.vue"

export default Vue.extend({
  name: "PregameIntroVideo",
  components: {
    VideoComponent,
    YTPlayer: () => import("@/components/VideoPlayers/YTPlayer.vue")
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      path: null
    }
  },
  computed: {
    ...mapGetters("auth", ["client", "clientID"]),
    videoCode() {
      return this.client?.lobbyIntroVideo
    },
    isOurVideo() {
      return isVideo(this.videoCode)
    }
  },
  created() {
    if (!this.videoCode) {
      return this.navigateBack()
    }

    setTimeout(() => {
      PregameVideoStorage.add(this.clientID)
    }, 2000)
  },
  methods: {
    navigateBack() {
      this.$navigator.navigateTo(`/lobby/${this.clientID}`)
    }
  }
})
</script>
